import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import "../EventComponent.css"



import bass_picknick_layer_0 from "../images/bp_mo/bach_au.jpg";


import bass_picknick_layer_0_desktop from "../images/bp_desk/back_aut.jpg";




import SoundSystemComponent from './SoundSystemComponent';
import AboutUsComponent from './AboutUsComponent';
import InstagramComponent from './InstagramComponent';
import MenuComponent from './MenuComponentAlt';

class EventComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            component: <EventComponent />,
            homepage: false,
            buttText: "FIO Homepage"
        };
    }

    handleToggle = () => {
        if (this.state.homepage) {
            this.setState({ component: <EventComponent /> });
            this.setState({ buttText: "FIO Bass Picknick" });
            this.setState({ homepage: false });
        }
        else {
            this.setState({ component: <MenuComponent /> });
            this.setState({ buttText: "FIO Homepage" });
            this.setState({ homepage: true });
        }

    }


    render() {
        return (
            <div>
                <div className='desktop_flyer'>
                    <div className='event_comp'>
                        <img className="flyer_background" src={bass_picknick_layer_0_desktop} alt="Bass Picknick Flyer" />

                 

                    </div>
                    <h2 className="down_arrow" onClick={() => window.scrollTo({ top: 700, behavior: "smooth" })}>	▼ ▼ ▼  </h2>
                </div>

                <div className='mobile_flyer'>
                    <div className='event_comp'>
                        <img className="flyer_background" src={bass_picknick_layer_0} alt="Bass Picknick Flyer" />


                    </div>
                    <h2 className="down_arrow" onClick={() => window.scrollTo({ top: 700, behavior: "smooth" })}>	▼ ▼ ▼  </h2>
                </div>



                <div className='event_infos'>
                    <h1>Event Infos</h1>
                    <h2></h2>

                    <p>Der FIO-SV stellt sein Soundystem ans Ufer des Hilmteich und lädt zum Free Open Air!</p>
                    <h2>Satter Sound und treibender Bass inklusive</h2>
                    <p>Mehrere namhafte Grazer Kollektive schicken ihre Künstler an den Start und garantieren ein buntes Lineup und eine unvergessliche Party.</p>
                    <p>Genres: <br/> House - Deep House - Techno <br/>120-140 BPM</p>
                    <h2></h2>



                   

                    <h2></h2>
                    <h2></h2>
                    <h2></h2>
                    <h2></h2>
                    <h2></h2>
                    <h2></h2>
                </div>
            </div>

        );
    }
}

export default EventComponent;