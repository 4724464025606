import React from 'react';
import ReactDOM from 'react-dom/client';
import "../MenuComponent.css";
import "../Mobile.css";
import fio_banner from "../images/FIO-Banner.jpg";
import fio_rack from "../images/FIO-Rack.jpg";
import fio_driver from "../images/FIO-Driver.jpg";
import ChartComponent from './ChartComponent';
import construction_image from "../images/construction.png";
import stack_image from "../images/stack1.jpg";

class SoundSystemComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {

        };
    }



    render() {
        return (
            <div>
                <h1>Sound System</h1>
      


                <div style={{marginTop:"50px", textAlign:"center"}}>
                <img className='sound-system-image'  src={stack_image} alt = "FIO Soundsystem" />
               
                </div>


            
                {/*
                <div className='content-detail' >

               
                    <p>- 2x Limmer P2 Derivat zwei Wege Aktiv (Moderne Version mit neuem Hochtöner und 15"er) - </p>

                    


                    
                </div>

            <table className='sound-system-stats'>
                <tr>
                
                    <td >
                    <ChartComponent  />
                    </td>
                </tr>

            </table>

            <p>- 6x 18" Bassreflex Sub @2.5kW Bassreflex mit High Performance Treiber (18Sound NTLW5000) -</p>
                    <p>- Amprack:   2x FP14000 (Bass)
                        - 1x FP10000 (Top)
                        - Monacor DSM 48Lan mit Steuerung iPad -</p>
                    <p>- 2x Lambda Labs TX2A -</p>
                    <p> Interessiert? Für weitere Infos, Verleih des Soundsystems oder Kooperationen: office@fio-sv.at </p>
        */}
            </div>
        );
    }
}

export default SoundSystemComponent;