import React, { Component } from 'react';
import ReactDOM from 'react-dom/client';
import "../MenuComponent.css"

import fio_banner from "../images/FIO-Banner.jpg";
import fio_rack from "../images/FIO-Rack.jpg";
import fio_driver from "../images/FIO-Driver.jpg";
import fio_ddj from "../images/FIO-Ddj.jpg";
import fio_jaga from "../images/FIO-Jaga.jpg";
import fio_robert from "../images/FIO-Robert.jpg";
import fucking_deepfry from "../images/FIO-Deep.jpg";

import SoundSystemComponent from './SoundSystemComponent';
import AboutUsComponent from './AboutUsComponent';
import InstagramComponent from './InstagramComponent';
import HomeComponent from './HomeComponent';
import "../Mobile.css";

class MenuComponent extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            title_img: fio_banner,
            curr_component: <HomeComponent />,
            image_class: "title-image",
            events_hide: true,
            show_menu: false,
        };
    }



    handleImage = (image, component) => {
        if (this.state.title_img != image) {
            this.setState({ title_img: image });
            this.setState({ curr_component: component })
        }
    }

    toggleImageSmall = () => {
        if (this.state.image_class == "title-image")
            this.setState({ image_class: "title-image-small" });
        else if ((this.state.image_class == "title-image-small"))
            this.setState({ image_class: "title-image" });
    }

    toggleMenu = () => {
        if (this.state.show_menu)
            this.setState({ show_menu: false });
        else
            this.setState({ show_menu: true });
    }


    render() {


        var display_content = "block";
        if (this.state.image_class == "title-image")
            display_content = "none";

        var display_menu = "none";
        var background_style = "";
        if (this.state.show_menu) {
            display_menu = "table";
            background_style = "blur(5px)";
        }

        var event_style;
        if (this.state.events_hide)
            event_style = "none";
        else
            event_style = "inline";
        return (
            <div >
                <img className={this.state.image_class} src={this.state.title_img} alt="FIO Welcome Image" />
                <table className='nav-bar'>
                    <tbody>
                        <tr>
                            <td
                                onMouseOver={() => { this.handleImage(fio_banner, <HomeComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            > Home</td>
                            <td onMouseOver={() => { this.handleImage(fio_rack, <SoundSystemComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            >Sound System</td>
                            <td onMouseOver={() => { this.handleImage(fio_driver, <AboutUsComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            >About Us</td>
                            <td onMouseOver={() => { this.handleImage(fio_robert, <InstagramComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            >Instagram</td>
                            <td style={{ display: event_style }} onMouseOver={() => { this.handleImage(fio_robert, <InstagramComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            >Upcoming Events</td>
                        </tr>
                    </tbody>
                </table>

                {/*<div className='menu-mobile'>
                    <table style={{ display: display_menu }} className='nav-bar-mobile'>
                        <tbody>
                            <tr> <td
                                onMouseOver={() => { this.handleImage(fio_banner, <HomeComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            > Home</td> </tr>
                            <tr>  <td onMouseOver={() => { this.handleImage(fio_rack, <SoundSystemComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            >Sound System</td></tr>
                            <tr>   <td onMouseOver={() => { this.handleImage(fio_driver, <AboutUsComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            >About Us</td></tr>
                            <tr>  <td onMouseOver={() => { this.handleImage(fio_robert, <InstagramComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            >Instagram</td></tr>
                            <tr>  <td style={{ display: event_style }} onMouseOver={() => { this.handleImage(fio_robert, <InstagramComponent />) }}
                                onClick={() => { this.toggleImageSmall() }}
                            >Upcoming Events</td></tr>

                        </tbody>
                    </table>

                    <div className='menu-button-mobile'>
                        <h1 onClick={() => {  this.toggleMenu() }}>MENU</h1>

                     </div> 
                </div>*/}

                <div style={{ filter: background_style, display: display_content }} className='content'>
                    {this.state.curr_component}
                </div>



            </div>
        );
    }
}

export default MenuComponent;