import React from 'react';
import ReactDOM from 'react-dom/client';
import "../MenuComponent.css"
import "../Mobile.css";
import ImpressumComponent from './ImpressumComponent';

import fio_banner from "../images/FIO-Banner.jpg";
import fio_rack from "../images/FIO-Rack.jpg";
import fio_driver from "../images/FIO-Driver.jpg";
import { Line } from "react-chartjs-2";

import { Chart as ChartJS } from 'chart.js/auto'





class ChartComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {

    };
  }




render() {
  const data =  {
    labels: [31, 50, 60, 125, 250, 300, 400, 500, 1000, 10000  ],
    datasets: [
      {
        label: "SUB",
        data: [0.7, 0.8, 0.9, 1, 0.9, 1, 0.6, 0.8, 0.3, 0.4],
        borderColor: "#07CFF6",
        lineTension: 0.4,        
        radius: 3      
       
      }
    ]
  };

  const options= {
    responsive: true,
    aspectRatio: 1/1,
    
    plugins: {
     
    },
    scales: {
      x: {
        display: true,
        type: 'logarithmic',
        
        grid: {
          display: true ,
          color: "white",
          
        },
        border: {
          dash: [2,4],
          
        }
      },
      y: {
        display: true,
        grid: {
          display: true ,
          color: "white",
        },
        border: {
          dash: [2,4],
        }
      }
    
    }
  };

  return (
    <div >
      <Line data={data} options={options} />
    </div>
  );
}
}

export default ChartComponent